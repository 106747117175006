/* body, html {
  height: 100vh;
  margin: 0;
  padding: 0;
} */

.changing-text-container{
  position: relative;
  margin-top: -5vw;

}

.carousel-image {
  height: 90vh;
  width: 100%;
  object-fit: cover;
}

.changing-text {
  position: absolute;
  bottom: 40%; 
  left: 10%;
  padding: 10px;
  width: 55%; 
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.9);
}
.changing-text-first {
  position: absolute;
  bottom: 40%; 
  left: 10%;
  padding: 10px;
  width: 55%; 
  color: white;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.9);
  text-align: left;
}

 .herButton{
    position:absolute;
    bottom: 43%; 
    left: 60%;
    padding: 10px;
  }
  .mySpan{
    top:54.2%;
    position: absolute;
    /* bottom: 35%;  */
    left: 10%;
    padding: 10px;
    width: 45%; 
    color: white;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.9);
    text-align: left;
    /* background-image: linear-gradient(to right, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0.3) 90%, rgba(0,0,0,0.3) 80%, transparent);
    padding-left: 10%; */
   }

  @media only screen and (max-width: 768px) {
    .changing-text-container .slick-slider {
      max-height: 300px;
    }
    .changing-text-container{
      height: 300px;
      margin-top: -45px;
    }
    .carousel-image {
      height: 300px;
      width: 100%;
      overflow: hidden;
    }

    .changing-text-container h1{
      font-size: 16px;
    }
    .mySpan{
      font-size: 12px;
     }
    .herButton{
      position:absolute;
      bottom: 25%; 
      left: 10%;
    }
  
  }
  