.SolutionsPage {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top:3vw;
  padding-bottom:3vw;
  /* border: solid red; */
}

 .SolutionsHeader {
  color: black;
  text-align: center;
  padding:1vw;


}
.SolutionsHeader h2 {
  margin-top: 0;  
  font-size: 2.5vw;
  
}

.solutionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* gap: 2vw; */
}

.card {
  background-image: image();
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33vw;
  width: 25vw; 
  background: white;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid;
  /* border-image: linear-gradient(to right, #1882FF, #36EBCA); */
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-image-slice: 1;
}

.cardContent {
  position: absolute;
  bottom:0;
  left: 0%;
  width: 100%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  margin:0;
}

.cardContent p {
  margin: 1vh;
  text-align: center;
  font-size: 1.2vw;

}

.labelText{
  height:10vh;
  
}
.solutionButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}


 @media screen and (max-width: 768px) {
  .SolutionsPage {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .solutionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    gap: 40px;
    
  }
  .SolutionsHeader h2 {
    font-size: 20px;
  }
  .card {
    width:100%;;
    height:250px;
  } 
  .cardContent{
    font-size: 12px;
  }

 }

  




