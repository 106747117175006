.career-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20vw;
    position: relative;
    color: white;
}

.career-hero img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.career-hero-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(10, 37, 59,0.9) 30%, rgba(0,0,0,0.3) 90%, rgba(0,0,0,0.3) 80%, transparent);
    align-items: left;
    justify-content: center;
    padding-left: 10vw;
    box-sizing: border-box;
}

.career-route {
    font-size: 1vw;
}

.career-hero-title h2 {
    font-size: 2vw;
    font-weight: bolder;
    margin:0;
}

.career-hero-subtitle p {
    font-size: 1.5vw;
}

@media screen and (max-width: 768px){
    .career-hero {
        height: 200px;
    }
    .career-route {
        font-size: 10px;
    }
    .career-hero-title h2{
        font-size: 16px;
    }
    .career-hero-subtitle p{
        font-size: 12px;
    }
}
