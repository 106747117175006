.blogpagedetails {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blogdetails-loading-waitTime {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw;
    height: 4vw;
    margin: auto;
}
.blogdetails-loading-waitTime img {
    width: 100%;
    height: 100%;
}
.blog-hero-pic {
    margin-top: 3vw;
    width: 90%;
    height: 25vw;
    margin-left: auto;
    margin-right: auto;
    
}
.blog-hero-pic img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.blogpage-full-article {
    margin-top: 3vw;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;

}
.blogpageFullArticle-title {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    text-align: left;
    background: transparent;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #1882FF, #36EBCA); 
    border-image-slice: 1; 
    border-width:0 0 2px 0;
    width: 100%;
    padding-bottom: 1vw;
}
.blogpageFullArticle-title h2 {
    padding-bottom: 0;
    font-size: 2vw;
}
.blogpageFullArticle-title p {
    font-size: 1.2vw;
    margin-top: 0.3vw;
    margin-bottom: 0;
}
.blogpageFullArticle-description {
    margin-top: 2vw;
    
}
.blogpageFullArticle-description p{
    font-size: 1.2vw;
    margin-top: 0.3vw;
    text-align: justify;
    hyphens: auto;
    
}
.blogpageFullArticle-description h2{
    font-size: 2vw;
    
}
.recent-posts {
    margin-top: 5vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    width: 90%;
    margin: auto;
}
.recent-posts .recent-blogs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    gap: 3vw;
    width: 100%;

    margin-bottom: 3vw;
}
.recent-posts h1 {
    font-size: 2vw;
    padding-left: 4.5vw;
    color: black;
    /* margin-bottom: 0; */
    text-align: center;
}
.see-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 3vw;
}

@media screen and (max-width: 768px) {
    .blogpage-full-article {
        border-bottom: 1px dashed grey;
    }
    .blogpageFullArticle-title h2 {
        font-size: 16px;
    }
    .blogpageFullArticle-title p {
        font-size: 12px;
    }
    .blogpageFullArticle-description p{
        font-size: 12px;
    }
    .blogpageFullArticle-description h2{
        font-size: 16px;
    }
    .recent-posts h1 {
        font-size: 16px;
    }
    .recent-posts {
        display: none;
    }

}