.heroSection {
    background-image: url("../../images/WhoWeAre/heroImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    margin-top: 3vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-left: 16vw;
    box-sizing: border-box;
}
.hs-main-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    text-align: left;
    box-sizing: border-box;


}
.hs-main-container .route {
    font-size: 1vw;
}
.hs-main-container .hero-title {
    font-size: 4vw;
    font-weight: bolder;
    margin-bottom: 0;
    margin-top: 0;
}
.hs-main-container .hero-subtitle {
    margin-top: 0;
    margin-bottom: 1.5vw;
    font-size: 1.5vw;
}
.hs-main-container .hero-description {
    line-height: 2vw;
    font-size: 1.2vw;
}

.herobutton {
    margin-top: 2vw;
}


@media screen and (max-width: 768px) {
    
    .heroSection {
        justify-content: center;
        padding-left: 0;
    }
    .hs-main-container{
        width: 70%;
    }
    .hs-main-container .route {
        font-size: 10px;
    }
    .hs-main-container .hero-title {
        font-size: 24px;
    }
    .hs-main-container .hero-subtitle {
        font-size: 15px;
    }
    .hs-main-container .hero-description {
        font-size: 12px;
        line-height: 16px;
        
    }
    .contact-card-div {

        width: 100%;
        margin-right: 0;
        margin-top: 0;
    
    }

  }