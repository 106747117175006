.myBlogsList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    gap: 3vw;
    width: 90%;
    margin: auto;
    margin-top: 3vw;
    margin-bottom: 3vw;
}
.cover-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
    width: 100%;
}
.loading-waitTime {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw;
    height: 4vw;
    margin: auto;
    margin-bottom: 4vw;
}
.loading-waitTime img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.no-blog {
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.all-blogs-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: auto;
    margin-top: 3vw;
    margin-bottom: 3vw;
    width: 90%;
}
@media screen and (max-width: 768px) {
    .myBlogsList {
        flex-direction: column;
    }

}