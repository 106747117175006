.owners-Header-Page {
  display: flex;
  justify-content: left;
  align-items: center;
  background-image: url("../../../images/owners/ownersheader.jpg");
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; 
  box-sizing: border-box;
  padding-top: 4vw;
  padding-bottom: 4vw;
  height: 40vw;
}
.owners-mask {
  display: flex;
  justify-content: left;
  align-items: center;
  color: white;
  box-sizing: border-box;
  padding-top: 4vw;
  padding-bottom: 4vw;
  height: 40vw;
  width: 100%;
  background-image: linear-gradient(to right, rgba(10, 37, 59, 0.6) 30%, rgba(0,0,0,0.3) 90%, rgba(0,0,0,0.3) 80%, rgba(0,0,0,0.3) 100%);
}

.ownersHeader { /* Updated class name */
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  text-align: left;
  box-sizing: border-box;
  padding-left: 16vw;
}

.ownersHeader h1 { /* Updated class name */
  font-size: 3vw;
  font-weight: bolder;
  margin-bottom: 0;
}

.ownersHeader h3 { /* Updated class name */
  margin-top: 0.5vw;
  margin-bottom: 2vw;
  font-size: 1.8vw;
}

.ownersHeader h4 { /* Updated class name */
  font-size: 1vw;
  padding-bottom:1vw;
}

.ownersHeader p { /* Updated class name */
  line-height: 2vw;
  font-size: 1.2vw;
}

.ownersHeaderButton { /* Updated class name */
  margin-top: 2vw;
}

.ownersHeaderCard { /* Updated class name */
  position: absolute;
  height: 30%;
  left: 60%;
  top: 80%;
  filter: none;
}

@media screen and (max-width: 768px) {
  .owners-Header-Page, .owners-mask {
    height: 300px;
  }
  .ownersHeader { 
    text-align: left;
    align-items:left;
    width: 80%;
  }

  .ownersHeader h1 { /* Updated class name */
    font-size: 25px;
  }

  .ownersHeader h3 { /* Updated class name */
    font-size: 15px;
  }

  .ownersHeader p { /* Updated class name */
    line-height: 16px;
    font-size: 12px;
  }
}
