.comparisonSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a253b;
  padding: 4vw 0vw 4vw 0vw;
}
.comparison-container{
  display:flex;
  justify-content: center;
  align-items: center;
  gap:3vw;
  padding: 0vw 3vw 0vw 3vw;

  } 
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: 50%;
    height: 30vw;
    position: relative;
    padding: 0vw 3vw 0vw 3vw;
    /* border: solid yellow; */
  }
  .text-container h2 {
    font-size: 2.5vw;
    margin-top: 0;
    margin-bottom: 2vw;
  }
  .text-container p {
    font-size:1.2vw;
    height: 15vw;
    max-height: 15vw;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    hyphens: auto;
  }

 .comparison-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 30vw;
  border: 1px solid;
    border-image: linear-gradient(to right, #1882FF, #36EBCA);
    border-image-slice: 1;
  /* border: solid yellow; */
 }
 .comparison-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
 }
 .comparisonButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;

 }
     
@media only screen and (max-width: 768px) {
  .comparison-container {
    flex-direction: column;
    width: 70%;
    padding: 20px 0 20px 0;
    height: 100%;
    /* gap: 50px; */
    /* border: solid yellow; */

  }
  .text-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  .text-container h2 {
    font-size: 20px;
  
  }
  .text-container p {
    font-size:12px;
    min-height: 150px;

  
  }
  .comparison-picture {
    width: 100%;
    min-height: 150px;
  }
  .comparisonButton {
    position: unset;
  }
}
 
