@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.contactus-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40vw;
    position: relative;
    color: white;
    border-radius: 20px;
    /* margin-bottom: 0; */

}
.contactus-hero img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.contactus-hero-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(10, 37, 59, 0.9) 30%, rgba(0,0,0,0.3) 90%, rgba(0,0,0,0.3) 80%, transparent);
    align-items: left;
    justify-content: center;
    padding-left: 6vw;
    box-sizing: border-box;
    
}

.contactus-title h2 {
    font-size: 3vw;
    font-weight: bolder;
    margin:0;
}
.contactus-subtitle p{
    font-size: 1.5vw;
    margin-top: 0.2vw;
}

.form-container{
    display: flex;
    justify-content: center;
    color: black;
    font-size: 1.2vw;
    top: 0;
    right: 0;
    padding: 3vw 0 3vw 0;

}
.form-container .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 40%;
    padding: 3vw 0 3vw 0;
    background-color: rgba(148, 146, 146, 0.1);
}
.user-info-first {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: solid red; */
}
.user-info-first input {
    font-family: 'Montserrat', sans-serif;
    font-size: 1vw;
    border: 1px solid grey;
    border-radius: 5px;
    width: 15vw;
    height: 1.8vw;
}
.first-column, .second-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* border: solid blue; */
}
.input-divs {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin-bottom: 1.2vw;
}
.user-info-textarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    padding: 0vw 2vw 0vw 2vw;
}
.user-info-textarea textarea {
    width: 88%;
    height: 10vw;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1vw;
    border: 1px solid grey;
}
.user-info-textarea label {
    width: 100%;
}
.input-divs label {
    width: 100%;
}
.company-category, .communicationStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 90%;
    margin: auto;
    gap: 0.5vw;
}
.company-category p, .communicationStyle p{
    margin-bottom: 0;
}
.checkboxes {
    display: flex;
    justify-content: left;
    align-items: center;

}
.company-category input, .communicationStyle input{
    border: 1px solid grey; 
    border-radius: 5px;
    width: 1.5vw;
    height: 1.5vw;
    /* margin-right: 1vw; */
    margin: 0 1vw 0 2vw;
   
}
.form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-top: 2vw;
}
.form-button button {
    background-color: red;
    width: 100%;
    height: 3vw;
    color: white;
    font-size: 1.2vw;
    font-weight: bold;
    border: none;
    /* border-radius: 10px; */
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0px 0px 10px rgba(10, 37, 59, 0.5);
    cursor: pointer;
}
.form-button button:hover {
    background-color: rgba(255, 0, 0, 0.7);
  }
.email-sent-popout {
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgb(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}
.email-sent-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40vw;
    height: 14vw;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    padding: 1vw 3vw 1vw 3vw;
    text-align: center;
}
.email-sent-contents button{
    background-color: red;
    width: 7vw;
    height: 2.5vw;
    color: white;
    font-size: 1vw;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0px 0px 10px rgba(10, 37, 59, 0.5);
    cursor: pointer;
}

.email-sent-contents button:hover {
    background-color: rgba(255, 0, 0, 0.7);
  }


  @media screen and (max-width: 768px) {
    .form-container .contact-form {
        width: 80%;
    }
    .user-info-first {
        flex-direction: column;
        width: 90%;
        margin: auto;
        font-size: 12px;
    }
    .user-info-first input {
        display: flex;  
        width: 100%;
        height: 20px;
        border-radius: 2px;
        font-size: 12px;
        /* border: solid red; */
    }
    .first-column, .second-column{
        width: 100%;
    }
    .input-divs {
        width: 100%;
    }
    .user-info-textarea {
        align-items: center;
        width: 90%;
        margin: auto;
        font-size: 12px;
        padding: 0vw;
        /* border: solid red; */
    }
    .user-info-textarea textarea {
        width: 100%;
        height: 120px;
        border-radius: 2px;
        font-size: 12px;

    }
    .company-category, .communicationStyle {
        gap: 5px;
    }
    .company-category p, .communicationStyle p{
        font-size: 12px;
    }
    .checkboxes {
        font-size: 12px;
        gap: 5px;
    }
    .company-category input, .communicationStyle input{
        border-radius: 0px;
        width: 15px;
        height: 15px;
    }
    .form-button {
        width: 90%;
        margin: auto;
        margin-top: 20px;
    }
    .form-button button {
        height: 25px;
        font-size: 12px;
    }
    .email-sent-contents {
        width: 250px;
        height: 150px;
        border-radius: 5px;
        font-size: 12px;
    }
    .email-sent-contents button{
        width: 60px;
        height: 20px;
        font-size: 12px;
        border-radius: 5px;
    }

  }