.platform-message {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-height: 50vh;
    margin: auto;
    text-align: center;
    border: 1px solid rgb(197, 194, 194);
    border-radius: 10px;
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding: 2vw;
}

@media screen and (max-width: 768px) {
    .platform-message {
        width: 80%;
        padding: 0px 20px 0px 20px;
    }
    .platform-message h2{
        font-size: 16px;
    }
    .platform-message p{
        font-size: 12px;
    }
}