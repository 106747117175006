.la-heroSection {
  background-image: url("../../../images/projectsPage/Hero-Service-Background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: left;
  align-items: center;
  color: white;
  margin-top: 5vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  padding-left: 16vw;
  box-sizing: border-box;
}
.la-hs-main-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  text-align: left;
  box-sizing: border-box;


}
.la-route {
  font-size: 1vw;
}
.la-hero-title {
  font-size: 4vw;
  font-weight: bolder;
  margin-bottom: 0;
}
.la-hero-subtitle {
  margin-top: 0;
  margin-bottom: 1.5vw;
  font-size: 1.5vw;
}
.la-hero-description {
  line-height: 2vw;
}

.la-herobutton {
  margin-top: 2vw;
}


@media screen and (max-width: 768px) {
  
  .la-heroSection {
      justify-content: center;
      padding-left: 0;
  }
  .la-hs-main-container{
      width: 70%;
  }
  .la-route {
      font-size: 10px;
  }
  .la-hero-title {
      font-size: 24px;
  }
  .la-hero-subtitle {
      font-size: 15px;
  }
  .la-hero-description {
      font-size: 12px;
      line-height: 16px;
      
  }
  .la-contact-card-div {

      width: 100%;
      margin-right: 0;
      margin-top: 0;
  
  }

}