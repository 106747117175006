@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.nav-button {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3vw;
  background-color: #0a253b;
  border: 2px solid white;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
}

.navbar {
  position: relative;
  display: flex;
  justify-content:right;
  z-index: 100;
  padding: 0 1vw 0 1vw;
  box-sizing: border-box;

}
.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-logo{
  display: flex;
  left: 2.3vw;
  position: absolute;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5vw;
  justify-content: center;
  align-items: center;

}
.navbar-logo a {
  text-decoration: none;
}


.menu-icon {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 1.5vw;
  font-size: 16px;


}

.nav-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 2vw;
  margin-right: 1vw;
  padding: 0;

}
.nav-list a {
  text-decoration: none;

}
.nav-list li {
  margin: 0;
}

.nav-link-menu{
  cursor: pointer;
}
.nav-link-menu:hover:not(.nav-link-submenu){
  font-weight: bold;
}
.dropdown-arrow {
  font-size: 1vw;
  padding: 0;
  color: rgba(0, 0, 0, 0.7);
}
.sub-menu{
  position: absolute;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1.3vw;
  min-width: 11vw;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.sub-menu a {
  text-decoration: none;
  text-align: left;
}

.sub-menu .nav-link-submenu {
  list-style-type: none;
  margin-left: 0;
  font-weight: normal;
  padding-left: 1vw;
  align-items: center;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
}

.sub-menu .nav-link-submenu:hover{
  background-color: #1d4261;
  color: white;
}


.close-button {
  display: none;
  cursor: pointer;
  color: red;
  font-size: 25px;
  font-weight: bolder;
}


@media screen and (max-width: 768px) {
  .navbar {
    height: 40px;
  }
  .nav-list {
    display: none;
 }
  .menu-icon {
    display: flex;
    margin-right: 12px;
  }
  .navbar-logo{
    font-size: 12px;
    margin-left: 10px;
  }


  .nav-list.show{
    display: block;
    top: 100%;
    width: 100%;
    left: 0;
    margin-top: 0;
    background-color: #000;
    box-sizing: border-box;
    padding: 10px 40px 30px 20px;
    position: absolute;
    z-index: 20;

  }
  .nav-list.show .nav-link-menu {
    font-size: 16px;
    padding-left: 12px;
  }
  .service-tab-space::after {
    content: "\2003"
  }
  .aboutus-tab-space::after {
    content: "\00a0\00a0";
  }
  .dropdown-arrow {
    font-size: 10px;
    color: white;
  }

  .nav-list.show .sub-menu li{
    margin: 0;
    padding-left: 3vw;
  }

  .sub-menu{
    position: static;
    background-color: transparent;
    
      
  }
  .close-button {
    display: flex;
  }
  .nav-list li {
    margin: 10px 0;
  }

}