.login-form-section{
    top: 53%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 25%;
    /* border: solid red; */

}
.login-form {
    border: 1px solid rgb(176, 172, 172);
    padding: 2vw;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
    color: black;
    margin: auto;

}
.login-form input{
    margin-bottom: 15px;
    width: 15vw;
    height: 2vw;
    border: 1px solid rgb(192, 178, 178);
    border-radius: 10px;
    font-size: 1.2vw;
    font-family: 'Montserrat', sans-serif;
}
.login-button{
    font-size: 1.2vw;
    width: 7vw;
    height: 2vw;
    background-color: white;
    border: 1px solid rgb(192, 178, 178);
    border-radius: 10px;
    cursor: pointer; 
}
.login-button:hover {
    background-color:beige;
}
.error-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}
.error-msg p {
    color: red;
    text-align: center;
}

@media screen and (max-width: 760px) {
    .login-form-section{
        width: 90%;
        top: 200px;
    }
    .login-form {
        border: 0;
        font-size: 12px;
    }
    .login-form input{
        margin-bottom: 15px;
        width: 200px;
        height: 20px;
        border-radius: 5px;
        font-size: 12px;
    }
    .login-button{
        font-size: 12px;
        width: 80px;
        height: 20px;
    }
}