.blogslist-container {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    color: black;
    gap: 3vw;
    width: 100%;
}
.blogslist-gradient {
    background: linear-gradient(to bottom, #1882FF, #36EBCA); 
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.blogslist-gradient-mask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 1px;
    width: 100%;
    height: 100%;
    gap: 0;
    /* border: solid yellow; */
    
}
.blogslist-pics {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 18vw;
}
.blogslist-pics img {
    object-fit: cover;
    width: 100%;
    image-rendering: auto; 
}
.blogslist-text {
    padding: 2vw 2vw 0 2vw;
    box-sizing: border-box;
    width: 100%;
    height: 20vw;
    /* min-height: 210px; */

    
}
.blogslist-text .blogslist-title, .blogslist-text .blogslist-description {
    margin-bottom: 1vw;
}
.blogslist-title h2 {
    font-size: 1.7vw;
}
.blogslist-description {
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    
    
}
.blogslist-description p {
    font-size: 1.2vw;
    margin: 0;
    padding: 10px;
}


.blogslist-date-button {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    gap: 4vw;
    width: 100%;
    padding: 2vw 2vw 1vw 2vw;
    box-sizing: border-box;
    
}
.blogslist-date-button p, .blogslist-description p, .blogslist-author p {
    font-size: 1.2vw;
}
.blogslist-author p {
    font-style: italic;
    margin-top: 0;
}
@media screen and (max-width: 768px) {
    .blogslist-container {
        flex-direction: column;
        width: 100%;
        
    }
 
    .blogslist-gradient {
        width: 100%;
    }
    .blogslist-pics{
        width: 100%;
        height: 200px;

    }
    .blogslist-pics img {
        width: 100%;

    }
    .blogslist-text {
        height: 200px;
        width: 100%;
        
    }

    .blogslist-title h2 {
        font-size: 16px;
    }
    .blogslist-description p, .blogslist-date-button p, .blogslist-author p {
        font-size: 12px;
    }




}