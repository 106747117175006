.innov-sustain-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    padding: 3vw;
    box-sizing: border-box;
    gap: 3vw;
}
.innovation, .sustainability {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: 50%;
    padding: 1vw 5vw 1vw 3vw;
    min-height: 35vw;
}
.sustain-image {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sustain-image img {
    object-fit: cover;
    width: 100%;
    height: 15vw;
    filter: hue-rotate(120deg);
}
.sustain-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .innov-sustain-section { 
        flex-direction: column;
        gap: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .innovation, .sustainability {
        width: 300px;
    }
    .innovation h2, .sustainability h2 {
        font-size: 16px;
        margin-bottom: 0;
    }
    .innovation p, .sustainability p {
        font-size: 12px;
    }
    .sustain-image img {
        height: 100px;
    }
    .sustain-button {
        margin-top: 12px;
    }
}