
   .Blog {
    background-color: #0a253b;
    color:white;
    margin: 0;
    padding-bottom: 5vw;
  }

  .BlogHeader {
    text-align: center;
    padding: 4vw 0vw 2vw 0vw;
    /* width: 94%; */
    margin: auto;
  }
  

  .BlogHeader h2 {
    padding-bottom: 1vw;
    font-size: 2.5vw;
    margin-top:0;
    /* border-bottom: 1px solid white; */
  }


  .BlogCard {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3vw;
  }
  
  .BlogContent {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 50%;
    height: 30vw;
    padding: 0vw 3vw 0vw 0vw;
    position: relative;
    /* border: solid yellow; */
  }

  .BlogContent h3{
    font-size:1.8vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  .BlogContent p{
    font-size:1.2vw;
    max-height: 15vw;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    hyphens: auto;
    /* margin-bottom: 2vw; */
    /* border: solid yellow; */
  }
  

  .BlogPicture {
    width: 50%;
    margin-left: 3vw;
    height: 30vw;
    border: 1px solid;
    /* border-image: linear-gradient(to right, #1882FF, #36EBCA); */
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    border-image-slice: 1;
    /* border: solid yellow; */
  }

  .BlogImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .author {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1vw;
    margin-bottom:3vw;
  }
  .author p{
    margin:0;
  }


  .blogButton {
    display: flex;
    margin-top: 3vw;

    justify-items: center;
    justify-content: center;
    /* width: 100%; */
    position: absolute;
    bottom: 0;
    right: 3vw;

  }
  @media screen and (max-width: 768px) {
 
    .BlogCard{
      display: flex;
      flex-direction: column; 
      align-items: center;
      gap:3vw;
      height:auto;
    }
  
    .BlogContent {
      width:70%;
      height:auto;
    }
    .BlogPicture{
      width:70%;
      margin:auto;
      height:30vw;
    }
    .BlogHeader h2 {
        font-size: 20px;
    }
    .BlogContent h2{
      font-size: 16px;
    }
    .BlogContent h3 {
      font-size: 16px;
    }
    .BlogContent p {
          font-size: 12px;
          max-height: 150px;
    
    }
    .blogButton {
      /* margin-top: 50px; */
      position:unset;

      width: 100%;

  
    }
    
    };
      
  
  
  
  