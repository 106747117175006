.vendor-benefits-Page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color:white;
  color:black;

}

.vendor-benefits-cards {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start; 
  flex-direction: row;
  padding: 10vw 0vw;

}

.vendorBenefitsContainer,
.vendorImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 25vw;
}

.vendorBenefitsContainer p {
  font-size: 1.2vw;
  padding-top:1vw;
  margin:0vw;
  cursor: pointer;
  text-align: justify;
  hyphens: auto;
  height: 10vw;
  /* border: solid red; */
}
.vendorBenefitsContainer span {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
.vendorImageContainer {
  position: relative;
  border: 1px solid;
  /* border-image: linear-gradient(to right, #1882FF, #36EBCA); */
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-image-slice: 1;
  background-size: cover;
  background-position: center;
  height: 30vw;
  border-width: 1px;
  cursor: pointer;

}


.vendorImage-label {
  position: absolute;
  bottom: 0.01%;
  left: 0%;
  width: 100%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.6vw;
  border:none;
}
.vendorPopout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.vendorPopout-content {
  background-color: white;
  padding: 2vw 5vw 2vw 5vw;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 70%;
  height: 40vw;
  text-align: justify;
  hyphens: auto;
  overflow-y: auto;
}

.vendorPopout-content p {
  font-size: 1.2vw;
  min-height: 30vw;
}
.vendorPopout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2vw;
}
.vendorPopout-button button {
  background-color: red;
  width: 7vw;
  height: 2.5vw;
  color: white;
  font-size: 1vw;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 0px 0px 10px rgba(10, 37, 59, 0.5);
  cursor: pointer;
}
.vendorPopout-button button:hover {
  background-color: rgba(255, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .vendor-benefits-Page {
    width: 90%;
    margin: auto;
  }
  .vendor-benefits-cards {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px 0 20px 0;
    gap: 30px;
  }

  .vendorBenefitsContainer,
  .vendorImageContainer {
    width: 100%;  
    height: 300px;

  }
  .vendorBenefitsContainer p  {
    width: 90%;
    margin: auto;
    font-size: 12px;
    margin-bottom:30px;
  }
  .vendorImage-label {
  font-size: 16px;
  }
  .vendorPopout-content {
    height: 60%;
    padding: 15px 25px 15px 25px;
  }
  .vendorPopout-content h2 {
    font-size: 16px;
  }
  .vendorPopout-content p {
    font-size: 12px;
    min-height: 200px;
  }
  .vendorPopout-button button {
    width: 80px;
    height: 30px;
    color: white;
    font-size: 12px;
    border-radius: 5px;
  }
}
 

