.us-text-container{
  color:black;
  
}
.us-gallery-container{
  width: 90%;
  margin: auto;
 margin-top: 5%;
  margin-bottom: 5%;
}
.us-project-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2%;
 align-items: center;
 
  border: solid;
  border-width: 0.2vw;
  border-image: linear-gradient(to right, #1882FF, #36EBCA);
  border-image-slice: 1;
  /* border: solid red; */
}
.us-text-container{
  padding: 2%;
  
}
.us-image-container img{
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  /* padding: 2%; */
}

.us-image-container{
  padding: 2%;
}



.us-text-container {
  display: flex;
  flex-direction: column; /* Ensure children stack vertically */
}

.date-button-container {
  display: flex;
  justify-content: space-between; 
  align-items: flex-start; 
  margin-top: auto; 
}

.date-button-container p {
  margin: 0; 
}

.us-button {
  align-self: flex-end; 
}


@media only screen and (max-width: 768px) { 
  .us-project-container {
    display: block;
  }

  .us-gallery-container p,
  .us-gallery-container h3,
  .us-gallery-container h4 {
    font-size: 12px;

  }
  

  .us-gallery-container h1{
    font-size: 16px;
  }
}