.sfooter-section {
    background-image: url("../../images/footer/footer-bkgnd2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* margin-top: 3vw; */

 
}
.sfooter-container {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    /* border: solid red; */

}
.sfooter-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3vw 4vw 0vw 4vw;
    box-sizing: border-box;
    /* gap: 18vw; */
    /* border: solid yellow; */
}
.sfooter-collegium {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 30%;
    margin-right: 7vw;
    gap: 2vw;
    padding-top: 2vw;
    /* border: solid yellow; */
}
.sfooter-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    margin-left: -2.5vw;
}
.collegium-slogan p {
    font-size: 1.1vw;
    margin-top: 0;
    line-height: 2vw;
}
.sfooter-logo img {
    object-fit: cover;
    width: 100%;
}
.sfooter-socialmedia {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1.5vw;
    margin-top: 0vw;
    margin-bottom: 2vw;
    width: 100%;
    font-size: 1.1vw;
    /* box-sizing: border-box; */
}
.smdia-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.5vw;
    transition: transform 0.3s ease-in-out;
}
.smdia-icons img {
    width: 100%;
    object-fit: cover;


}
.smdia-icons img:hover {
    transform: scale(1.2);
}
.sfooter-about h5, .sfooter-contacts h5{
    margin-bottom: 0;
}
.sfooter-about .sfooter-links li{
    list-style-type: none;
    font-size: 1.1vw;
    padding-bottom: 0vw;
    margin-bottom: 1vw;

    
}
.sfooter-about .sfooter-links a {
    text-decoration: none;
    color: white;
}
.sfooter-about .sfooter-links a:hover {
    font-weight: bold;
    color: #36EBCA;
}

.sfooter-contacts .sfooter-contacts-links li{
    list-style-type: none;
    font-size: 1.1vw;
    padding-bottom: 3vw;
}
.sfooter-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px dashed white;
    width: 100%;
    height: 2vw;

}
.sfooter-copyright p {
    font-size: 1vw;
}

@media screen and (max-width: 768px) {

    .sfooter-top {
        flex-direction: column;
    }
    .sfooter-collegium {
        width: 100%;
    }
    .sfooter-logo {
        width: 100px;
        margin-left: -5px;
    }
    .collegium-slogan p {
        font-size: 12px;
        line-height: 16px;
    }
    .sfooter-socialmedia {
        gap: 20px;
        font-size: 12px;
    }
    .smdia-icons {
        width: 16px;
    }
    .sfooter-about {
        width: 100%;
        padding-top: 0;
        margin-top: 10px;

    }

    .sfooter-links {
        flex-direction: column;
    }


    .sfooter-about h5, .sfooter-contacts h5{
        margin-top: 10px;
        font-size: 14px;
    }
    .sfooter-about h5::after, .sfooter-contacts h5::after{
        content: ":";
    }
    .sfooter-about .sfooter-links, .sfooter-contacts .sfooter-contacts-links {
        padding-left: 0;
        display: flex;
        gap: 10px;
        margin-top: 10px;
        

    }
    .sfooter-contacts .sfooter-contacts-links {
        gap: 70px;
    }
    .sfooter-about .sfooter-links li{
        font-size: 12px;
        padding-left: 15px;
        margin-bottom: 0;
        
    }

    .sfooter-contacts .sfooter-contacts-links li{
        font-size: 12px;
        padding: 0;
    }
    .sfooter-copyright {
        height: 20px;
    }
    .sfooter-copyright p {
        font-size: 10px;
    }

}