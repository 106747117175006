@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


body{
  /* background-color: #4A5358; */
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3vw;
  color: white;


}


