.survey {
    width: 70%;
    margin: auto;
    color: black;
    min-height: 65vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
    /* background-color: rgba(148, 146, 146, 0.1); */
    border: 1px solid rgb(176, 172, 172);
    border-radius: 2px;


}
.header {
    padding: 1vw 3vw 1vw 3vw;
    
}

.header .sub-title{
    font-size: 1.2vw;
    text-align: justify;
    
}
.questions-answer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;

}
.question-answer-individual, .user-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3vw;
    border-top: 1px solid rgb(176, 172, 172);
    border-radius: 0px;
}

.user-details {
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-bottom: 1vw;
    padding: 2vw 3vw 1vw 3vw;
    gap: 1vw;

}
.user-details-guide {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}
.user-details-guide p {
    font-size: 1.2vw;
    margin-top: 0;
    margin-bottom: 0;
    
}
.user-details-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    /* gap: 3vw; */
    width: 100%;
    margin-top: 0;
    /* border: solid red; */
}
.user-details-fields input {
    width: 20vw;
    height: 2vw;
    border: 1px solid rgb(176, 172, 172);
    border-radius: 1px;
}
.survey-result {
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgb(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}
.result-popout {
    height: 20vw;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3vw;
    border: 1px solid rgb(176, 172, 172);
    border-radius: 10px;
    background-color: white;
    color: black;
    padding: 3vw;
}
.result-popout h3 {
    margin-bottom: 0;
}
.result-popout p {
    font-size: 1.2vw;
}
.question-answer-individual .question{
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    border-right: 1px dashed rgb(176, 172, 172);
    padding: 1vw 3vw 1vw 3vw;
    gap: 0;
    /* border: solid red; */
}
.question-answer-individual .question span {
    margin-bottom: 0;
    font-size: 1.2vw;
    font-weight: bold;
}
.question-answer-individual .question p {
    margin: 0;
    font-size: 1.2vw;
}
.question-answer-individual .answer{
    flex: 0.4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;

    /* border: solid green; */
}
.question-answer-individual .answer label{
    font-weight: bold;
}
.survey-button-div{
    display: flex;
    justify-content: center;
    align-items: center;

}
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3vw;
}
.survey-submit-button, .survey-result-high button, .survey-result-medium button, .survey-result-low button {
    font-size: 1.2vw;
    width: 10vw;
    height: 2.5vw;
    background-color: #f1356d;
    color: white;
    border: 1px solid rgb(192, 178, 178);
    border-radius: 1px;
    cursor: pointer;
    margin-top: 1vw;
}
.survey-submit-button {
    margin-bottom: 2vw;
}
.survey-result-medium button, .survey-result-low button {
    width: 10vw;
    background-color: transparent;
    color: black;
}
.survey-submit-button:hover, .survey-result-medium button:hover, .survey-result-low button:hover {
    font-weight: bolder;
}

.survey-result-high, .survey-result-medium, .survey-result-low {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    
    
}

@media screen and (max-width: 768px) {
    .survey {
        width: 90%;
        min-height: 550px;
    }
    .survey .title {
        font-size: 16px;
    }
    .header .sub-title{
        font-size: 12px;
    }
    .user-details-guide p {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .user-details-fields {
        font-size: 12px;
    }
    .user-details-fields input {
        width: 150px;
        height: 15px;
        border: 1px solid rgb(176, 172, 172);
        border-radius: 5px;
    }
    .question-answer-individual {
        font-size: 12px;
    }
    .question-answer-individual .question p,  .question-answer-individual .question span {
        font-size: 12px;
    }
    .survey-submit-button, .survey-result-high button, .survey-result-medium button, .survey-result-low button {
        width: 80px;
        height: 25px;
        font-size: 12px;
    }
    .survey-submit-button {
        margin-bottom: 12px;
    }
 
    .survey-result-high p, .survey-result-medium p, .survey-result-low p {
        font-size: 12px;
    }
    .result-popout {
        height: 200px;
        width: 70%;
        padding: 0px 25px 10px 25px;
    }
    .result-popout h3 {
        font-size: 16px;
    }
    .result-popout p {
        font-size: 12px;
    }
}