.meetourteam {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
    box-sizing: border-box;
    background-color: #F6FCFC;
}
.team-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.team-title h2{
    font-size: 2vw;
    margin-bottom: 3vw;
    color: black;
}
.team-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3vw;
}
.person{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25vw;
}
.team-gradient-div {
    background: linear-gradient(to bottom, #1882FF, #36EBCA); 
    width: 25vw;
    height: 33vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.person-pic-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: 99%;
    position: relative;
}
.person-pic-name img{
    width: 99.99%;
    height: 99.99%;
    object-fit: cover;
    
} 
.person-name{
    position:absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.person-name p{
    color: white;
    text-align: center;
    font-size: 1.2vw;
    font-weight: bold;
    transition: font-size 10ms ease-in-out;
}

.person-description p{
    font-size: 1.2vw;
    color: black;
}
.team-gradient-div:hover .person-name{
    background-color: rgba(54, 235, 202, 0.7);
    color: black;
    font-size: 1.5vw;
}
@media screen and (max-width: 768px){
    .team-details {
        flex-direction: column;
    }
    .team-title {
        margin-top: 10px; 
        margin-bottom: 10px;       
    }
    .team-title h2 {
        font-size: 16px; 
    }
    .team-gradient-div {
        width: 300px;
        height: 400px;
    }
    .person {
        width: 300px;
    }
    .person-name{
        height: 50px;
    }
    .person-name p{
        font-size: 16px;
    }
    
    .person-description {
        margin-bottom: 10px;
    }
    .person-description p{
        font-size: 14px;
    }
    .team-gradient-div:hover .person-name{
        font-size: 16px;
    }

}