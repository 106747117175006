.projectsHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: white;
  margin-top: 5vh;
}

.projectsHeader h2 {
  font-size: 2.5vw;
  margin-bottom: 1vw;
  width: 100%;
  text-align: center;
}
.projectsHeader h3 {
  font-size: 1.8vw;
  margin: 0;
  width: 100%;
  text-align: center;
}



.ProjectPage {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  margin: auto;
}

.projectsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start; 
  justify-content: space-evenly;
  padding: 4vw 0vw  5vw 0vw ;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  width: 25vw;
  border: none;
  margin-top: 0;
}

.container h4 {
  font-size: 1.5vw;
  text-align:center;
}

.image-Container {
  position: relative;
  width: 25vw;
  border: 0.2vw solid;
  border-image: linear-gradient(to right, #1882FF, #36EBCA);
  border-image-slice: 1;
  background-size: cover;
  background-position: center;
  height: 25vw;
  margin: 0;
}



.projectButton {
  display: flex;
  padding-top: 2.5vw; 
  justify-content: center;
}


  @media (max-width: 768px) {
    .projectsHeader {
      display: flex;
      width: 100%;
      text-align: center;}

    .projectsHeader h2 {
        font-size: 20px;
    }
    .projectsHeader h3{
        font-size: 12px;
    }

    .projectsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 90%;
    }
  
    .container {
      width: 90%; 
    }
  
    .image-Container {
      width: 100%;
      height: 200px;
    }
  
    .container h4 {
      font-size: 12px;
      margin-bottom: 35px;
    }
  
    .projectButton {
      margin-left: initial;
      justify-content: center;
      align-items: center;
    }

}

  