.mission-vision {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6FCFC;
    color: black;
    padding-top: 4vw;
    padding-bottom: 4vw;
    box-sizing: border-box;
    
}

.mv-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
    box-sizing: border-box;
}
.mv-title-gradient {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #1882FF, #36EBCA); 
    width: 40vw;
    height: 12vw;
    /* border: solid red; */
    position: relative;

}
.mv-title {
    width: 101%;
    height: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    background-color: #F6FCFC;
    margin-bottom: 2px;
    box-sizing: border-box;
    /* border: solid yellow; */
    position: absolute;
}
.mv-title span {
    font-size: 4.5vw;
 
}
.mv-title h2{
    font-size: 2vw;
   
}
.mv-sub-title {
    padding: 2vw;
}
.mv-sub-title p {
    font-size: 1.2vw;
}
.missionVision-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6vw;
    
}
.mv-gradient {
    background: linear-gradient(to bottom, #1882FF, #36EBCA); 
    position: relative;
    width: 30vw;
    height: 35vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mission, .vision {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: 99%;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  
}
.mission img, .vision img {
    width: 99.9%;
    height: 99.9%;
    object-fit: cover;
}
.grey-mask {
    background: linear-gradient(to right, rgba(74, 83, 88, 0.7) 80%, rgba(74, 83, 88, 0.4)); 
    width: 99.9%;
    height: 99.9%;
    position: absolute;

}
.mission-text, .vision-text {
    position: absolute;
    color: white;
    width: 80%;
    text-align: justify;
    hyphens: auto;
    
}
.mission-text h2, .vision-text h2 {
    color: #E0FBFC;
    margin-bottom: 4vw;
    font-size: 2vw;
    text-align: center;
}
.mission-text p, .vision-text p{
   font-size: 1.2vw;
}


@media screen and (max-width: 768px) { 
    .mv-title-gradient {
        width: 270px;
        padding: 8px;
    }
  
    .mv-title span {
        font-size: 20px;
    }
    .mv-title h2 {
        font-size: 16px;
    }
    .mv-sub-title {
        width: 300px;

    }
    .mv-sub-title p{
        padding: 0 10px 10px 10px;
        text-align:center;
        font-size: 12px;
    }
 
    .missionVision-cards {
        flex-direction: column;
    }
    .mv-gradient {
        width: 300px;
        height: 300px;
    }

    .mission-text h2, .vision-text h2 {
        font-size: 20px;
    }
    .mission-text p, .vision-text p {
        font-size: 12px;
        text-align: left;
        line-height: 16px;
    }
}