.la-sng-heroSection {
  background-image: url("../../../images/projectsPage/Hero-Single-Project.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: left;
  align-items: center;
  color: white;
  margin-top: 5vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  padding-left: 16vw;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  
  
  .sng-project-container p,
  .sng-project-container h3,
  .sng-project-container h2,
  .sng-project-container h4 {
    font-size: 12px;

  }
  

  .sng-project-container h1{
    font-size: 16px;
  }

}
