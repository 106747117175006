.vendors-Testimony-Page{
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  background-color: #F6FCFC;
  color:black;
  padding:5vw 0vw 8vw 0vw;
}

.vendors-Testimony-Page h2 {
  padding: 6vw 0vw 3vw 0vw;
  font-size: 2vw;
  }


.vendors-Partners-Container {
  display: flex;
  flex-direction: row;
  gap:10vw;
  align-items: center;
}


.vendorsTestimonyHeader,
.vendorsPartners{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vendorsTestimonyHeader h1{
  margin-bottom:0vw;
  font-size: 3vw;
  font-weight: bolder;
}

.vendorsTestimonyHeader p{
  margin-top:3vw;
  font-size: 1.5vw;
}

.vendorsPartners{
  display:flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width:15vw;
}
.vendorsPartners p{
  margin:0;
  font-size: 1.2vw;
}

.vendorsPartnersImage{
  width:100%;
  height:6vw;
  margin-bottom:1vh;
}

.vendorsPartnersImage img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border:none;
}


  @media (max-width: 768px) {
    .vendorsTestimonyHeader{
      width:70%;
      height:auto;
      text-align: center;
    }
    
   .vendors-Partners-Container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width:80%;
      max-width: none; 
    }
    .vendorsPartners{
      width:60%;
      height:25vw;
      max-width: none; 
      height:auto;
    }

    .vendorsPartnersImage{
      width:50%;
    }

  }