
.projects-container a{
  color: blue;
}
.projects-container a:hover {
  background: linear-gradient(to bottom, #ffffff, #36EBCA);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}


/* @media only screen and (max-width: 768px) {
.projects-container{
 
  flex-direction: column;

}
} */