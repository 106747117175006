.vendors-Products-Page {
  display: grid;
  background-color: #F6FCFC;
  color: black;
}

.vendors-Products-Page h1 {
  padding: 3vw;
  font-size: 2.5vw;
  text-align: center;
}

.products-Container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17vw, 1fr));
  row-gap: 5vw; 
  column-gap: 10vw; 
  padding: 1vw 8vw 4vw 8vw;
}

.product{
  display: flex;
  flex-direction: column;
  justify-items: center;
} 
.product
p {
  font-size: 1.2vw;
  margin:0vw;
}
.product h4 {
  font-size: 1.5vw; 
  margin:0.5vw;
  text-align: center;
}

.productImage {
  width: 25%;
  max-width: 25%; 
  height: auto; 
  margin:auto;
  color:black;
}
.productImage img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Keeps the aspect ratio and fits the entire SVG within the container */
  border: none;
}

@media (max-width: 768px) {
  
  .products-Container {
    grid-template-columns: repeat(auto-fit, minmax(30vw, 1fr));
    gap: 5vw;
  }

  .vendors-Products-Page h1 {
    font-size: 20px;
  }
  .product
    h3 {
      font-size: 15px; 
    }
    .product
    p {
      font-size: 12px; 
    }
} 
