.contact-card {
    /* background: linear-gradient(to right, #1882FF, #36EBCA);  */
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    width: 28vw;
    height: 8vw;
    position: relative;
    z-index: 2;
}
.contact-card-mask {
    background-color: white; 
    width: 101%;
    height: 100%;
    border: 0;
    margin: 0;
    margin-top: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    position: absolute;


}
.contact-card-mask .contactCard-container {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-left: 3vw;
    margin-top: 0.3vw;
    margin-bottom: 2vw;
}
.contactCard-container .contact-title {
    font-size: 1vw;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 2vw;
}
.contactCard-container .contact-number {
    font-size: 1.2vw;
    color: #00215B;
    font-weight: bolder;
    margin-bottom: 0;
    letter-spacing: 1px;
}
.contactCard-container .contact-email {
    font-size: 1.2vw;
    color: #00215B;
    font-weight: bolder;
    margin-top: 0;
    letter-spacing: 1px;
}


@media screen and (max-width: 768px) {
    .contact-card { 
        width: 100%;
        height: 40px;
        position: relative;
        box-shadow:none;
        /* border: solid red; */
    }
    .contact-card-mask {
        margin-top: 3px;
        justify-content: center;
        align-items: center;


    }
    
    .contact-card-mask .contactCard-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 30px;
        
        
    }
    .contactCard-container .contact-title, .contactCard-container .contact-number, .contactCard-container .contact-email {
        font-size: 9px;
        margin: auto;
        letter-spacing: 0;  
    }
  }



