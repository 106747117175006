
.testimony {
  display: flex;
  flex-direction: row; 
  align-items:center; 
  color: black;
  padding: 4vw 3vw 4vw 3vw;
  text-align: center;
  background: white;
  justify-content:center;
  gap:2%;
 
}
.client {
  margin-bottom: 1vw;
}

.testimony h2,
.testimony h3,
.testimony h4,
.testimony h2
.testimony p {
  margin:0%;
}

.testimonyContainer{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vw;
  gap: 1vw;

}
.testimonyContainer h2 {
  margin: 0;
  margin-bottom: 3vw;
  font-size: 2.5vw;
}



.clientImage {
  height: 15vh;
  width: auto;
  border-radius: 50%;
  margin-top: 5%;
}

.testimonyImageContainer{
  display: flex;
  width:70%;
  /* margin-left: 2%; */
  overflow: hidden;
  height: 40vw;
  justify-content: center;
  align-items: center;

}

.testimonyImageContainer img {

  width: 100%;
  height: 100%;
  object-fit: cover;
  

}

.testimonyText {
  color: black;
  /* background-color: #0A253B; */
  border: 1px solid;
  /* border-image: linear-gradient(to right, #1882FF, #36EBCA); */
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-image-slice: 1; 
  text-align: left;
  /* margin: 2vh; */
  padding: 1vw 2vw 1vw 2vw;
  /* height: 30%; */
  position: relative;
} 

.testimonyButton {
  display: flex;
  margin-top: 1vw;
  margin-left: auto;
  justify-items: center;
  justify-content: right;
} 


@media only screen and (max-width: 768px) { 

  .testimony{
  flex-direction:column;
  gap: 0;


}

.testimonyContainer{
  width:fit-content ;
  height: auto;
  margin: 5%;

}
.testimonyContainer h2{
  font-size: 20px;
}

.testimonyImageContainer {
  width:fit-content;
  height: auto;
  margin: 5%;
}

.testimonyImageContainer img {
  height: 100%; 
  width: 100%; 
  object-fit: cover;
}
.testimony p,
.testimony h3,
.testimony h4
{
font-size: 12px;
}

.testimony h1{
  font-size: 16px;
}
}
  
  
  
  